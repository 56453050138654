body * { box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 { margin: 0.5em 0; padding: 0 }

a {
  color: #9F3400;
}

#root {
  display: flex;
}

.App {
  width: 100%;
}

.centerize {
  display: flex;
  width: 100vw;
  padding: 40px;
  height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.anchor {
  position: relative;
  top: -100px;
  display: block;
  width: 1px;
}

.anchor-link {
  text-decoration: none;
  color: #222;
}

.production-report .MuiTable-root td img {
  height: 30px;
}

@media print {
  body {
    color: black !important;
  }
  body * {
    color: inherit !important;
  }
  .hide-for-print {
    display: none !important;
  }
  #mainNav {
    display: none;
  }
  .production-report .MuiTable-root th,
  .production-report .MuiTable-root td {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .production-report .MuiTable-root td img {
    height: 20px;
  }
}

.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--in-selecting-range,
.react-datepicker .react-datepicker__day--in-range,
.react-datepicker .react-datepicker__month-text--selected,
.react-datepicker .react-datepicker__month-text--in-selecting-range,
.react-datepicker .react-datepicker__month-text--in-range,
.react-datepicker .react-datepicker__quarter-text--selected,
.react-datepicker .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker .react-datepicker__quarter-text--in-range {
    background: #9F3400;
}

.react-datepicker__input-container input {
    max-width: 135px;
}

.react-datepicker .react-datepicker__triangle {
    left: 50%;
}

.react-datepicker-popper {
    left: -78px!important;
}